.vertical-navigation {
    margin-bottom: 20px;
    background: $theme-color-light-gray7;
    padding: 32px;
    
    &__list {
        @include lib-list-reset-styles();
    }
    
    &__list-item {
        margin: 0;
        font-size: 15px;
    }
    
    &__anchor {
        @include lib-link(
            $_link-color: $text__color,
            $_link-color-visited: $text__color,
            $_link-color-hover: $text__color,
            $_link-color-active: $text__color
        );
    }
    
    &__current-item {
        color: $brand__primary__color;
        font-weight: 500;
    }
    
    strong {
        cursor: pointer;
        margin: 0;
        overflow: hidden;
        position: relative;
        word-break: break-all;
        z-index: 1;
        font-size: 15px;
        color: $theme-color-dark-gray5;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .vertical-navigation {
        display: flex;
        flex-direction: column;
        background: none;
        padding: 0;
    
        &__list-item {
            font-weight: $font-weight__regular;
            font-size: 16px;
        }
        
        .category-title {
            display: flex;
            align-items: center;
            background: $brand__primary__color;
            color: $color-white;
            font-weight: $font-weight__medium;
            padding: 10px 0 10px 15px;
            border-radius: 4px;
            font-size: 16px;
            position: relative;
            
            &:after {
                content: '+';
                font-size: 20px;
                position: absolute;
                right: 20px;
            }
        }
        
        &__list {
            display: none;
        }
    }
    
    .vertical-navigation.active {
        .category-title {
            &:after {
                content: '-';
            }
        }
        
        ul {
            line-height: 36px;
        }
        
        .vertical-navigation__list {
            display: block;
            background: $brand__primary__color;
            padding: 0 0 10px 15px;
            margin-top: -2px;
            border-radius: 0 0 4px 4px;
            
            a {
                color: $color-white;
            }
        }
    }
}
