$header__button-size: 45px !default;

@mixin header-button-size() {
    height: $header__button-size;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
    text-decoration: none;
}

@mixin header-button-open() {
    position: relative;
    z-index: 105;
    background-color: $color-white;
    color: $brand__primary__color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.header-chevron {
    @include lib-icon-font(
        $_icon-font-content         : $icon-chevron-down,
        $_icon-font-position        : after,
        $_icon-font-margin          : 0 0 0 7px,
        $_icon-font-size            : 11px,
        $_icon-font-color           : false ,
        $_icon-font-color-hover     : false,
        $_icon-font-color-active    : false,
        $_icon-font-display         : false
    );
    
    &.active {
        @include lib-icon-font-symbol(
            $_icon-font-content  : $icon-chevron-up,
            $_icon-font-position : after
        );
    }
}

.customer-welcome.active .action.switch::after,
.customer-welcome .action.switch::after {
    display: none;
}

.customer-name {
    @extend .header-chevron;
}

.page-header {
    @include lib-css(background-color, white);
    margin-bottom: 20px;
}

.sections.nav-sections {
    background-color: $header__navigation__background;
    border-bottom: $header__navigation__border;
}

.header-top-bar {
    @extend .abs-usps;
    padding: 5px 0;
    background-color: $color-white;
    border-bottom: 1px solid $border-color__base;
    
    p {
        margin: 0;
    }
    
    ul {
        display: flex;
        
        li {
            margin-right: 25px;
            margin-bottom: 0;
        }
    }
}

.header-top-bar-inner {
    display: flex;
    justify-content: space-between;
}

.header-top-bar-usps-container {
    display: flex;
}

.header {
    &.content {
        position: relative;
        display: flex;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
    &-icons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;
    }
    
    .wishlist-wrapper {
        @include lib-list-reset-styles();
        position: relative;
        
        .label {
            @include lib-visually-hidden();
        }
        
        .counter.qty {
            @extend .abs-number-badge;
        }
        
        a {
            @include lib-icon-font(
                $_icon-font-content: $icon-wishlist-empty,
                $_icon-font-color: $header-text-color,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 1
            );
            
            &:hover {
                &::before {
                    color: $header-icons-color-hover;
                }
            }
        }
    }
    
    &.links {
        .counter.qty {
            display: none;
        }
    }
    
    .minicart-wrapper {
        .action.showcart {
            @include header-button-size();
            
            
            &.active {
                @include header-button-open();
                
                &::before,
                &::after {
                    color: $brand__primary__color;
                }
            }
        }
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    max-width: 50%;
    position: relative;
    z-index: 5;
    
    img {
        display: block;
    }
    
    .page-print & {
        float: none;
    }
}

.navigation-menu-container {
    background: rgb(0, 77, 155);
    background: linear-gradient(41deg, rgba(0, 77, 155, 1) 0%, rgba(0, 77, 155, 1) 49%, rgba(121, 185, 40, 1) 79%);
}

.navigation-menu__top-link {
    color: white;
    
    &:hover,
    &:visited,
    &:active,
    &:focus {
        color: white;
    }
    
    a {
        color: white;
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .header {
        &.panel {
            display: flex;
            justify-content: space-between;
            
            a {
                color: $header-text-color;
            }
            
            .topbar__list,
            > .header.links {
                @include lib-list-inline();
                font-size: 0;
                
                &:first-child > li {
                    margin: 0 15px 0 0;
                }
                
                &:last-child > li {
                    margin: 0 0 0 15px;
                }
                
                > li {
                    margin: 0;
                    font-size: $font-size__base;
                    
                    &.welcome,
                    a {
                        line-height: 1.4;
                    }
                    
                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }
        
        &.content > &.links {
            @include lib-list-reset-styles();
            display: flex;
            justify-content: flex-end;
            margin-left: 10px;
            
            > li > a,
            .customer-name > span {
                color: $header-text-color;
                text-transform: capitalize;
                font-size: 14px;
                letter-spacing: 0.06em;
                font-weight: $font-weight__medium;
            }
            
            > li {
                margin: 0;
            }
            
            .customer-name {
                @include header-button-size();
                
                &[aria-expanded=true] {
                    @include header-button-open();
                    
                    > span {
                        color: $brand__primary__color;
                    }
                    
                    button {
                        &::after,
                        &:hover::after,
                        &:focus::after {
                            color: $brand__primary__color;
                        }
                    }
                }
            }
        }
        
        .wishlist-wrapper {
            a {
                @include header-button-size();
            }
        }
        
        .login-wrapper {
            .action.showlogin {
                @include header-button-size();
                
                &.active {
                    @include header-button-open();
                    
                    &::after {
                        color: $brand__primary__color;
                    }
                }
            }
        }
        
        &-icons {
            margin-right: -10px;
        }
    }
    
    .page-header {
        border: 0;
        margin-bottom: 0;
        
        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }
        
        .header.panel {
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }
        
        .switcher {
            display: inline-block;
        }
    }
    
    .logo {
        margin: 0;
    }
    
    .header {
        &.content > &.links {
            flex: 1;
            justify-content: space-between;
            align-items: center;
            margin-left: 50px;
        }
        
        .header-phonenumber {
            color: $brand__primary__color;
            font-size: 18px;
            font-weight: 700;
            
            a {
                color: $brand__primary__color
            }
        }
        
        input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"] {
            font-weight: 500;
        }
    }
}

//
//  Large Desktops
//  _____________________________________________

@include min-screen($screen__l) {
    .header {
        &.content > &.links {
            flex: 1;
            justify-content: space-between;
            align-items: center;
        }
    }
}

//
//  Screens between 768 and 1024
//  _____________________________________________

@include screen($screen__m, $screen__l) {
    .block-search {
        flex: 1;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-header {
        padding: 0;
    }
    
    .navigation-menu-container {
        background: none;
    }
    
    .header-phonenumber {
        display: flex;
        align-items: center;
        
        a {
            margin-left: 3px;
        }
    }
    
    .footer-menu__sub-menu-topall {
        display: none;
    }
    
    .nav-sections {
        .footer-menu {
            .footer-menu__sub-menu-list {
                display: block !important;
            }
        }
    }
    
    li.footer-menu__item.parent::after {
        display: none !important;
    }
    
    .navigation-menu__sub-menu-top-link {
        font-weight: 400 !important;
    }
    
    .header {
        .wishlist-wrapper {
            .counter.qty {
                transform: scale(0.6) translateX(10%) translateY(-38%);
            }
        }
        
        &.content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            padding: $indent__s;
            
            .links {
                display: none;
            }
            
            &::before,
            &::after {
                display: none;
            }
            
            .nav-toggle {
                position: initial;
            }
            
            .logo {
                margin: 0;
            }
            
            .block-search {
                width: 100%;
            }
            
            .block-search .label:before {
                margin-right: 0;
            }
            
            .block-search .control {
                border-top: 0;
            }
            
            .form.minisearch.active {
                .control {
                    background-color: $color-white;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    bottom: -63px;
                    z-index: 400;
                }
                
                .top-search__container {
                    padding: 0 15px;
                    border-bottom: 1px solid $border-color__base;
                }
            }
        }
        
        .showcart {
            margin-left: 15px;
        }
        
        .nav-toggle,
        &-icons {
            flex: 1;
        }
        
        .nav-toggle {
            order: 1;
        }
        
        .logo {
            order: 2;
        }
        
        &-icons {
            order: 3;
        }
        
        .block-search {
            order: 4;
        }
    }
}
