.breadcrumbs {
    @include lib-breadcrumbs();
}

.catalog-category-view {
    .breadcrumbs {
        padding: 0 0 15px 0;
        margin: 0;
        
        .items {
            font-size: 14px;
        }
    }
}
