$footer__background-color                  : false !default;

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

.footer {
    &.content {
        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }
}

.footer-menu {
    &,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }
    
    li {
        margin-bottom: 0;
        
    }
}

.sidebar-additional {
    .footer-menu__sub-menu-list {
        padding-left: 20px;
        
        a {
            padding: 0;
        }
    }
}

.footer-bottom {
    margin-bottom: 40px;
    text-align: center;
}

.footer-columns {
    margin-bottom: 40px;
}

.footer-copyright {
    font-size: 12px;
}

.footer-payment-providers {
    margin-bottom: 10px;
}

.after-footer-columns {
    .block.newsletter {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }
}

.detail-bar-wrapper {
    display: flex;
    width: 100%;
    
    .vtm-bar {
        width: 100%;
    }
}

.vtm-bar {
    .top-footer {
        margin: 0 auto 30px auto;
        padding: 0;
        background: linear-gradient(270deg, rgb(135,206,45) 0%, rgb(0,77,155) 79%);
        
        &__inner {
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 94px;
        
            h4 {
                color: $color-white;
                font-size: 16px;
                font-weight: $font-weight__medium;
                margin-bottom: 0;
            }
            
            span {
                color: $color-white;
                font-size: 16px;
            }
            
            .open {
                position: relative;
                padding-left: 15px;
                
                &:before {
                    content: '';
                    background: $theme-green2;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.top-footer {
    margin-bottom: 40px;
    padding: 15px 0;
    background: rgb(0,77,155);
    background: linear-gradient(41deg, rgba(0,77,155,1) 0%, rgba(0,77,155,1) 49%, rgba(121,185,40,1) 79%);
    color: $color-white;

    &__left {
        margin: 5px 15px 5px 0;
        font-size: 22px;
        font-weight: 700;
    }

    &__right {
        ul {
            @include lib-list-reset-styles();
            display: flex;
        }

        li {
            @include lib-icon-font(
                $_icon-font-content: false,
                $_icon-font-margin: 0 10px 0 0,
                $_icon-font-size: 22px
            );
            margin: 0;
            font-weight: $font-weight__medium;

            &:nth-child(1) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-delivery
                );

                &::before {
                    font-size: 20px;
                }
            }

            &:nth-child(2) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-undo
                );
            }

            &:nth-child(3) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-moon
                );

                &::before {
                    font-size: 19px;
                }
            }

            &:not(:first-child) {
                margin-left: 25px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .footer-columns {
        @include make-row();
        margin-bottom: 60px;

        .footer-menu {
            display: flex;

            &__item {
                flex: 1;
            }

            &__top-link,
            &__sub-menu-link {
                @include lib-link(
                    $_link-color: $text__color,
                    $_link-color-visited: $text__color,
                    $_link-color-hover: $text__color,
                    $_link-color-active: $text__color
                );
            }

            &__sub-menu-topall {
                display: none;
            }

            &__top-link {
                @include lib-heading(h4);
                @include lib-link(
                    $_link-color: $heading__color__base,
                    $_link-color-visited: $heading__color__base,
                    $_link-color-hover: $heading__color__base,
                    $_link-color-active: $heading__color__base
                );
                display: inline-block;
            }
        }

        .after-footer-columns,
        .footer-menu-container {
            @include make-col-ready();
        }

        .after-footer-columns {
            @include make-col(9);
        }

        .footer-menu-container {
            @include make-col(15);
        }
    }

    .footer-bottom {
        margin-bottom: 60px;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .top-footer {
        margin-bottom: 60px;

        &__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }
}

//
//  Mobile
//  _____________________________________________


@include max-screen($screen__m) {
    .footer.content .footer-menu {
        margin-right: -$layout-indent__width;
        margin-left: -$layout-indent__width;

        &__item {
            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-display: block
            );
            position: relative;
            margin: 0;
            padding: 15px 35px 15px $layout-indent__width;
            border-bottom: 1px solid $border-color__base;

            &:first-child {
                border-top: 1px solid $border-color__base;
            }

            &::before {
                position: absolute;
                top: 50%;
                right: $layout-indent__width;
                transform: translateY(-50%);
            }
        }

        &__top-link {
            @include lib-link(
                $_link-color: $text__color,
                $_link-color-visited: $text__color,
                $_link-color-hover: $text__color,
                $_link-color-active: $text__color
            );
        }

        &__sub-menu-list {
            display: none;
        }
    }

    .footer-columns {
        .block.newsletter {
            display: none;
        }

        .social-media {
            margin-top: 20px;
        }
    }

    .top-footer {
        display: none;
    }
    
    .vtm-bar {
        .top-footer {
            display: block;
            
            &__inner {
                flex-direction: column;
                align-items: flex-start;
                height: 100%;
                padding: 30px 0;
                
                .item {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
