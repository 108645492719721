.category-children {
    display: flex;
    flex-wrap: wrap;
    
    &__item {
        display: flex;
        width: calc(100% / 3);
        padding-right: 15px;
        margin-bottom: 15px;
        box-sizing: border-box;
        
        .inner {
            display: flex;
            width: 100%;
            border: 1px solid $border-color__base;
        }
        
        &:nth-child(3n) {
            padding-right: 0
        }
    }
    
    &__image-anchor {
        display: flex;
        height: 140px;
        background-size: cover;
        background-position: center;
        width: 100%;
    }
    
    &__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        
        .info {
            padding: 15px 10px 0 15px;
        }
        
        .bottom {
            padding: 0 15px 10px 15px;
            
            .view-category {
                @include lib-icon-font(
                    $_icon-font-content: $icon-chevron-up,
                    $_icon-font-size: 8px,
                    $_icon-font-position: after,
                    $_icon-font-color: $brand__primary__color
                );
                font-size: 14px;
                margin-top: 15px;
                
                &:after {
                    transform: rotate(90deg);
                    margin-left: 5px;
                    margin-top: -2px;
                }
            }
        }
        
        p {
            margin-bottom: 10px;
            font-size: 14px;
        }
        
        ul {
            margin: 0;
            padding-left: 0;
            
            li {
                position: relative;
                margin: 0;
                padding-left: 10px;
                font-size: 14px;
                list-style: none;
                
                &:before {
                    content: ".";
                    font-size: 20px;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    transform: translateY(-50%);
                }
            }
        }
    }
    
    &__anchor {
        @include lib-link(
            $_link-color: $theme-color-dark-gray5,
            $_link-color-visited: $theme-color-dark-gray5,
            $_link-color-hover: $theme-color-dark-gray5,
            $_link-color-active: $theme-color-dark-gray5
        );
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .category-children {
        &__item {
            width: 100%;
            padding: 0;
        }
    
        &__details {
            .info {
                strong {
                    font-size: 16px;
                }
            }
        }
    }
}
