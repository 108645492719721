.fotorama__nav-wrap--vertical {
    &.fotorama__nav-wrap {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
    }
}

.fotorama__nav-wrap--horizontal {
    position: relative;
    
    &.fotorama__nav-wrap {
        margin: $indent__base 0 $indent__base;
    }
}
