.traffic-light {
    margin-bottom: 5px;

    &__text {
        position: relative;
        padding-left: 35px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 1.2;
        letter-spacing: 0.04em;
        font-weight: $font-weight__bold;
        @include lib-icon-font(
            $_icon-font-content: $icon-delivery,
            $_icon-font-size: 28px
        );

        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &--in-stock {
            color: $accept__color;
        }

        &--out-of-stock {
            color: $brand__primary__color;
        }

        &.level-2,
        &.level-3 {
            color: $color-blue1;

            &:before {
                color: $color-blue1;
            }
        }
    }
}
