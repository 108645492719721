.category-sub-listing {
    display: flex;
    margin-bottom: 30px;
    
    &__item {
        border: 1px solid $border-color__base;
        width: 50%;
        padding: 15px 50px 10px 15px;
        position: relative;

        strong {
            color: $theme-color-dark-gray5;
        }
        
        p {
            margin: 0;
            font-size: 16px;
            line-height: 20px;
        }
        
        a {
            @include lib-icon-font(
                $_icon-font-content: $icon-chevron-up,
                $_icon-font-size: 10px,
                $_icon-font-color: $brand__primary__color,
                $_icon-font-margin: 0 -1px 0 0
            );
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 15px;
            bottom: 10px;
            width: 25px;
            height: 25px;
            border: 1px solid $brand__primary__color;
            border-radius: 50%;
            
            &:before {
                transform: rotate(90deg);
            }
            
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.type {
    margin-bottom:15px;
    padding-bottom: 5px;
    border-bottom: 1px solid $border-color__base;
    font-size: 16px;
    
    .category-name {
        text-transform: lowercase;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .category-sub-listing {
        &__item {
            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .category-sub-listing {
        flex-direction: column;
        
        &__item {
            border: 0;
            width: 100%;
            padding: 25px 25px 25px 0;
            box-sizing: border-box;
            position: relative;
            
            p {
                font-size: 15px;
            }
            
            strong {
                font-size: 16px;
            }
            
            &:after {
                content: '';
                width: calc(100% + 30px);
                height: 1px;
                background: $border-color__base;
                position: absolute;
                top: 0;
                left: -15px;
            }
    
            &:before {
                content: '';
                width: calc(100% + 30px);
                height: 1px;
                background: $border-color__base;
                position: absolute;
                bottom: 0;
                left: -15px;
            }
            
            &:last-child {
                padding: 5px 15px 25px 0;
                
                &:after {
                    display: none;
                }
            }
            
            a {
                margin-top: 10px;
            }
            
            &:not(:last-of-type) {
                margin-bottom: 15px;
            }
        }
    }
}
