@import './select2.scss';

.select2-container {
    .select2-results__option {
        margin: 0;

        .stock-notice {
            float: right;
            color: $theme-green;
            margin-left: 10px;

            &.level-0 {
                color: $theme-color-dark-gray1;
            }

            &.level-2,
            &.level-3 {
                color: $color-blue1;
            }
        }

        &--highlighted {
            .stock-notice {
                color: #fff;

                &.level-0 {
                    color: $theme-color-dark-gray1;
                }

                &.level-2,
                &.level-3 {
                    color: #fff;
                }
            }
        }
    }
}
