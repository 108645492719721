.banner-grid {
    @include make-row();
    @extend .abs-margin-for-blocks-and-widgets;
    margin-bottom: 20px;
    
    &__item {
        @include make-col-ready();
        @include make-col(8);
        @extend .wysiwyg-img;
        padding-bottom: 16px;
    }
}

@include max-screen($screen__m) {
    .banner-grid {
        display: none;
    }
}
