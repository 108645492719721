.blog-list {
    &__title {
        a {
            color: $product-title-color;
            font-size: 16px;
            font-weight: $font-weight__medium
        }
    }

    &__item {
        margin-bottom: 25px;
    }

    &__image {
        height: 0;
        margin-bottom: 15px;
        padding-bottom: 62%;
        background-position: center;
        background-size: cover;
    }
    
    &__meta {
        color: #9199a2;
        font-size: 13px;
    }
}

.blog-list-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}

@include min-screen($screen__m) {
    .blog-list {
        &__row {
            @include make-row();
            margin-bottom: -25px;
        }

        &__item {
            @include make-col-ready();
            @include make-col(8);
        }
    }
}
