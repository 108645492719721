@include lib-font-face(
    $family-name: $font-family-name__alternative,
    $font-path: '../fonts/Ff-good/bold/GoodPro-CondBold',
    $font-weight: regular,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Gt-america/bold/GTAmerica-Bold',
    $font-weight: 700,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Gt-america/medium/GTAmerica-regular',
    $font-weight: $font-weight__regular,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Gt-america/medium/GTAmerica-Medium',
    $font-weight: $font-weight__medium,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

.std {
    @include lib-typography-cms-lists();
}
