//
//  Product Lists
//  _____________________________________________

.product-overview.grid {
    .product-item {
        margin-bottom: $indent__l;
    }
}

.products {
    margin-top: $indent__xs;
    margin-bottom: $indent__xs;
    
    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            
            &.swiper-wrapper {
                flex-wrap: nowrap;
            }
        }
    }
}

.cms-index-index {
    .widget {
        .item {
            height: auto;
            border: 1px solid #dddddd;
        }
        
        .product-item-link {
            font-size: 14px;
            font-family: $font-family-name__base;
            text-transform: capitalize;
        }
    }
    
    .product-item-name {
        line-height: 14px !important;
    }
}

.product {
    &-items {
        @extend .abs-reset-list;
    }
    
    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        padding: 20px;
        
        .products-grid & {
            display: inline-block;
            
            .product-item-name {
                min-height: 65px;
            }
        }
        
        .products-grid &-name {
            font-size: 20px;
            color: #3e3f42;
            text-transform: uppercase;
            font-family: $font-family-name__alternative;
        }
        
        &-name {
            @extend .abs-product-link;
            display: block;
            word-wrap: break-word;
            hyphens: auto;
            margin-bottom: 5px;
        }
        
        &-info {
            max-width: 100%;
        }
        
        &-actions {
            display: none;
            
            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto;
                    
                    &::before {
                        margin: 0;
                    }
                    
                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }
        
        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }
            
            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }
        
        .price-final_price.price-box {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            justify-content: flex-end;
            
            .price {
                white-space: nowrap;
                margin-top: 5px;
            }
        }
        
        .special-price,
        .minimal-price {
            margin: 0;
            
            .price-wrapper {
                display: inline-block;
            }
            
            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }
        
        .special-price {
            display: block;
            color: $brand__primary__color;
            
            .price {
                color: $brand__primary__color;
                font-weight: $font-weight__medium;
                font-size: 17px;
            }
        }
        
        .old-price {
            span.price {
                font-size: 14px;
            }
        }
        
        .minimal-price {
            .price-container {
                display: block;
            }
        }
        
        .minimal-price-link {
            margin-top: 5px;
        }
        
        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }
        
        .price-from {
            margin: 0;
        }
        
        .price-to {
            display: none;
        }
        
        .tocompare {
            @include lib-icon-font-symbol($icon-compare-full);
        }
        
        .tocart {
            @extend .btn--rounded;
            @include lib-icon-font(
                    $icon-expand,
                $_icon-font-size: 12px,
                $_icon-font-text-hide: true
            );
            @include lib-icon-font(
                    $icon-cart,
                $_icon-font-size: 17px,
                $_icon-font-position: after
            );
            white-space: nowrap;
        }
    }
}

.sidebar {
    .subcategories {
        padding: 0;
        
        li,
        ul {
            list-style: none;
        }
        
        a {
            color: $text__color
        }
    }
}

.price-container {
    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }
    
    .weee {
        &::before {
            content: '(' attr(data-label) ': ';
        }
        
        &::after {
            content: ')';
        }
        
        + .price-excluding-tax {
            &::before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .photo-wrapper {
        position: relative;
        margin-right: 20px;
    }
    
    .product-item-details {
        width: 100%;
    }
    
    .product {
        &-item {
            width: 100%;
            
            &.item {
                padding-left: 0;
                padding-right: 0;
            }
            
            &-name {
                margin-top: 0;
            }
            
            &-info {
                display: flex;
                padding: 20px 0;
                border-bottom: 1px solid $border-color__base;
            }
        }
        
        .price-box {
            margin-bottom: 5px;
        }
    }
    
    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
    
    .product-list-wrapper {
        display: flex;
        
        &__left {
            min-width: 70%;
            padding-right: 25px;
            box-sizing: border-box;
        }
        
        &__right {
            min-width: 30%;
        }
    }
    
    .view-product-btn {
        display: flex;
        justify-content: flex-start;
        color: $color-white;
        background: $brand__primary__color;
        padding: 5px 10px;
        border-radius: 5px;
        position: relative;
        margin-bottom: 5px;
        
        span {
            @include lib-icon-font(
                $_icon-font-content: $icon-chevron-down,
                $_icon-font-size: 12px,
                $_icon-font-position: after
            );
            
            &::after {
                transform: rotate(-90deg);
                position: absolute;
                right: 10px;
                top: 8px;
            }
        }
        
        &:hover {
            text-decoration: none;
        }
    }
    
    .price-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    
    .second-attributes {
        tr {
            display: flex;
            justify-content: space-between;
            width: max-content;
        }
    }
    
    .list-attribute-groups {
        .additional-attributes {
            .wrapper {
                display: flex;
                align-items: center;
                
                &__left {
                    display: flex;
                    flex: 1;
                    min-width: 120px;
                }
                
                &__right {
                    padding-left: 5px;
                    font-weight: $font-weight__regular;
                }
            }
            
            th,
            td {
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
            
            tr {
                border-bottom: 1px solid $border-color__base;
            }
        }
    }
    
    .price-container {
        .price-label {
            margin-right: 10px;
        }
    }
}

.view-product-btn {
    display: none;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .products-grid {
        .products.product-items {
            .swatch-attribute {
                display: none;
            }
        }
    }
    
    .products-list {
        .product-item-info {
            flex-direction: column;
        }
        
        .product-list-wrapper {
            flex-direction: column;
        }
    }
}

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;
            
            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
    
    .product-reviews-summary,
    .product-item-details [class^=swatch] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product {
        &-item {
            .products-list &-name {
                font-size: 18px;
            }
            
            &-actions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                
                .actions-primary + .actions-secondary {
                    margin: 10px 0;
                    padding-left: 10px;
                    white-space: nowrap;
                    
                    & > * {
                        white-space: normal;
                    }
                    
                    & > .action:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    
    .action-wrapper {
        display: flex;
        justify-content: space-between;
    }
    
    .products-list {
        .action-wrapper {
            flex-direction: column;
        }
        
        .actions-secondary {
            display: inline;
        }
        
        .product-item-actions {
            display: inline-block;
        }
        
        .product-item-description {
            padding: 10px 0;
        }
    }
}
