.video-wrapper {
    h2 {
        @extend h1;
        color: $brand__primary__color;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid $border-color__base;
    }
}

.video-block {
    display: flex;
    
    &__video {
        display: flex;
        flex-direction: column;
        width: calc(100% / 3);
        padding-right: 15px;
        margin-bottom: 15px;
        box-sizing: border-box;
    
        &:nth-child(33) {
            padding-right: 0
        }
    }
    
    &__info {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        border: 1px solid $border-color__base;
        padding: 15px 10px;
        box-sizing: border-box;
    }
    
    &__title {
        color: $theme-color-dark-gray4;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .video-block {
        flex-direction: column;
        
        &__video {
            width: 100%;
        }
    }
}